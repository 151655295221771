import { lazy } from "react";

//test push
const dashboardRoutes = [
  // <Route path='*' exact={true} component={My404Component} />
  {
    path: "*",
    component: lazy(() => import("./views/NotFound")),
    layout: 1,
  },
  {
    path: "/",
    component: lazy(() => import("./views/Home")),
    layout: 2,
  },
  //#region System Start

  //#region Country Start
  {
    path: "/country",
    component: lazy(() => import("./views/panels/others/country/Country")),
    layout: 1,
  },

  {
    path: "/country/add",
    component: lazy(() => import("./views/panels/others/country/CountryForm")),
    layout: 1,
  },
  {
    path: "/country/update",
    component: lazy(() => import("./views/panels/others/country/CountryForm")),
    layout: 1,
  },

  //#endregion Country end
//#region User Start
  {
    path: "/users",
    component: lazy(() => import("./views/panels/others/user/User")),
    layout: 1,
  },
  {
    path: "/users/add",
    component: lazy(() => import("./views/panels/others/user/UserForm")),
    layout: 1,
  },
  {
    path: "/users/update",
    component: lazy(() => import("./views/panels/others/user/UserForm")),
    layout: 1,
  },
  {
    path: "/users/permission",
    component: lazy(() => import("./views/panels/others/user/UserPermission")),
    layout: 1,
  },

//#region User End
  //Language region start
  {
    path: "/language",
    component: lazy(() => import("./views/panels/others/language/Language")),
    layout: 1,
  },
  {
    path: "/language/add",
    component: lazy(() =>
      import("./views/panels/others/language/LanguageFrom")
    ),
    layout: 1,
  },
  {
    path: "/language/update",
    component: lazy(() =>
      import("./views/panels/others/language/LanguageFrom")
    ),
    layout: 1,
  },
  // Language region end
  // Tv Channel
  {
    path: "/tvchannel/add",
    component: lazy(() =>
      import("./views/panels/others/matchTvChannel/MatchTvChannelForm")
    ),
    layout: 1,
  },
  {
    path: "/tvchannel/update",
    component: lazy(() =>
      import("./views/panels/others/matchTvChannel/MatchTvChannelForm")
    ),
    layout: 1,
  },
  {
    path: "/tvchannel",
    component: lazy(() =>
      import("./views/panels/others/matchTvChannel/TvChannelTable")
    ),
    layout: 1,
  },
  // Tv Channel end

  //#region Seasons Start
  {
    path: "/seasons",
    component: lazy(() => import("./views/panels/others/season/Season")),
    layout: 1,
  },
  {
    path: "/seasons/add",
    component: lazy(() => import("./views/panels/others/season/SeasonForm")),
    layout: 1,
  },
  {
    path: "/seasons/update",
    component: lazy(() => import("./views/panels/others/season/SeasonForm")),
    layout: 1,
  },

  //#endregion Seasons end

  //#region Promo Start
  {
    path: "/promotions",
    component: lazy(() => import("./views/panels/others/promotion/Promotion")),
    layout: 1,
  },
  {
    path: "/promotions/add",
    component: lazy(() =>
      import("./views/panels/others/promotion/PromotionForm")
    ),
    layout: 1,
  },
  {
    path: "/promotions/update",
    component: lazy(() =>
      import("./views/panels/others/promotion/PromotionForm")
    ),
    layout: 1,
  },
  //#endregion Promo Start

  //#region Group-Promo Start
  {
    path: "/group-promotions",
    component: lazy(() =>
      import("./views/panels/others/groupPromotions/GroupPromotion")
    ),
    layout: 1,
  },
  {
    path: "/group-promotions/add",
    component: lazy(() =>
      import("./views/panels/others/groupPromotions/GroupPromotionForm")
    ),
    layout: 1,
  },
  {
    path: "/group-promotions/update",
    component: lazy(() =>
      import("./views/panels/others/groupPromotions/GroupPromotionForm")
    ),
    layout: 1,
  },
  //#endregion Group-Promo Start

  //#endregion System end

  //#region cricket Tournament Start
  {
    path: "/cricket/tournament",
    component: lazy(() =>
      import("./views/panels/cricket/tournament/Tournament")
    ),
    layout: 1,
  },
  {
    path: "/cricket/tournament/detail",
    component: lazy(() =>
      import("./views/panels/cricket/tournament/TournamentDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/cricket/tournament/add",
    component: lazy(() =>
      import("./views/panels/cricket/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/cricket/tournament/update",
    component: lazy(() =>
      import("./views/panels/cricket/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/cricket/country-tournaments",
    component: lazy(() =>
      import("./views/panels/cricket/tournament/CountryTournament")
    ),
    layout: 1,
  },
  //#endregion Tournament end

  //#region cricket contest
  {
    path: "/cricket/contest",
    component: lazy(() => import("./views/panels/cricket/contest/Contest")),
    layout: 1,
  },
  {
    path: "/cricket/contest/detail",
    component: lazy(() =>
      import("./views/panels/cricket/contest/ContestDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/cricket/contest/add2test",
    component: lazy(() =>
      import("./views/panels/cricket/contest/ContestForm2")
    ),
    layout: 1,
  },
  {
    path: "/cricket/contest/add",
    component: lazy(() => import("./views/panels/cricket/contest/ContestForm")),
    layout: 1,
  },
  {
    path: "/cricket/contest/update",
    component: lazy(() => import("./views/panels/cricket/contest/ContestForm")),
    layout: 1,
  },
  {
    path: "/cricket/contest/add-participant",
    component: lazy(() =>
      import("./views/panels/cricket/contest/AddParticipant")
    ),
    layout: 1,
  },
  {
    path: "/cricket/contest/league-table",
    component: lazy(() => import("./views/panels/cricket/contest/LeagueTable")),
    layout: 1,
  },

  //#endregion contest end

  //#region cricket competitor
  {
    path: "/cricket/competitor",
    component: lazy(() =>
      import("./views/panels/cricket/competitor/Competitor")
    ),
    layout: 1,
  },
  {
    path: "/cricket/competitor/detail",
    component: lazy(() =>
      import("./views/panels/cricket/competitor/CompetitorDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/cricket/competitor/update",
    component: lazy(() =>
      import("./views/panels/cricket/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/cricket/competitor/add",
    component: lazy(() =>
      import("./views/panels/cricket/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/cricket/competitor/add-coach",
    component: lazy(() =>
      import("./views/panels/cricket/competitor/AssignCoachToCompForm")
    ),
    layout: 1,
  },
  {
    path: "/cricket/competitor/add-competitor-to-contest",
    component: lazy(() =>
      import("./views/panels/cricket/competitor/AddCompToContestForm")
    ),
    layout: 1,
  },
  {
    path: "/cricket/competitor/add-squad",
    component: lazy(() => import("./views/panels/cricket/competitor/Squad")),
    layout: 1,
  },
  //#endregion competitor end

  //#region cricket player
  {
    path: "/cricket/player",
    component: lazy(() => import("./views/panels/cricket/player/Player")),
    layout: 1,
  },
  {
    path: "/cricket/player/detail",
    component: lazy(() =>
      import("./views/panels/cricket/player/PlayerDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/cricket/player/update",
    component: lazy(() => import("./views/panels/cricket/player/PlayerForm")),
    layout: 1,
  },
  {
    path: "/cricket/player/add",
    component: lazy(() => import("./views/panels/cricket/player/PlayerForm")),
    layout: 1,
  },
  {
    path: "/cricket/player/squad",
    component: lazy(() =>
      import("./views/panels/cricket/player/AddPlayerToComp")
    ),
    layout: 1,
  },
  {
    path: "/cricket/player/statistics",
    component: lazy(() => import("./views/panels/cricket/player/PlayerStats")),
    layout: 1,
  },
  {
    path: "/cricket/player/bat-stats/update",
    component: lazy(() =>
      import("./views/panels/cricket/player/PlayerBattingStatsForm")
    ),
    layout: 1,
  },
  {
    path: "/cricket/player/ball-stats/update",
    component: lazy(() =>
      import("./views/panels/cricket/player/PlayerBowlingStatsForm")
    ),
    layout: 1,
  },
  //#endregion player end

  //#region cricket coach
  {
    path: "/cricket/coach",
    component: lazy(() => import("./views/panels/cricket/coach/CricketCoach")),
    layout: 1,
  },
  {
    path: "/cricket/coach/detail",
    component: lazy(() =>
      import("./views/panels/cricket/coach/CoachDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/cricket/coach/add",
    component: lazy(() => import("./views/panels/cricket/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/cricket/coach/update",
    component: lazy(() => import("./views/panels/cricket/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/cricket/coach/add-role",
    component: lazy(() => import("./views/panels/cricket/coach/CoachRoleForm")),
    layout: 1,
  },
  //#endregion coach

  //#region cricket official
  {
    path: "/cricket/official",
    component: lazy(() => import("./views/panels/cricket/official/Official")),
    layout: 1,
  },
  {
    path: "/cricket/official/detail",
    component: lazy(() =>
      import("./views/panels/cricket/official/OfficialDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/cricket/official/add",
    component: lazy(() =>
      import("./views/panels/cricket/official/OfficialForm")
    ),
    layout: 1,
  },
  {
    path: "/cricket/official/update",
    component: lazy(() =>
      import("./views/panels/cricket/official/OfficialForm")
    ),
    layout: 1,
  },
  //#endregion official

  //#region cricket venue
  {
    path: "/cricket/venue",
    component: lazy(() => import("./views/panels/cricket/venue/Venue")),
    layout: 1,
  },
  {
    path: "/cricket/venue/detail",
    component: lazy(() =>
      import("./views/panels/cricket/venue/VenueDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/cricket/venue/add",
    component: lazy(() => import("./views/panels/cricket/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/cricket/venue/update",
    component: lazy(() => import("./views/panels/cricket/venue/VenueForm")),
    layout: 1,
  },
  //#endregion venue

  //#region cricket match
  {
    path: "/cricket/match",
    component: lazy(() => import("./views/panels/cricket/match/Match")),
    layout: 1,
  },
  {
    path: "/cricket/match/add",
    component: lazy(() => import("./views/panels/cricket/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/cricket/match/update",
    component: lazy(() => import("./views/panels/cricket/match/MatchForm")),
    layout: 2,
  },
  {
    path: "/cricket/match/player-of-match",
    component: lazy(() =>
      import("./views/panels/cricket/match/PlayerOfMatchForm")
    ),
    layout: 1,
  },
  {
    path: "/cricket/match/official",
    component: lazy(() =>
      import("./views/panels/cricket/match/MatchOfficialForm")
    ),
    layout: 1,
  },
  {
    path: "/cricket/match/detail",
    component: lazy(() =>
      import("./views/panels/cricket/match/MatchDetailPage")
    ),
    layout: 1,
  },
  {
    path: "/cricket/match/lineup",
    component: lazy(() =>
      import("./views/panels/cricket/match/lineup/LineupPlayerForm")
    ),
    layout: 1,
  },
  {
    path: "/cricket/match/scorecard",
    component: lazy(() =>
      import("./views/panels/cricket/match/scorecard/Scorecard")
    ),
    layout: 1,
  },
  {
    path: "/cricket/match/add-detail",
    component: lazy(() =>
      import("./views/panels/cricket/match/commentary/AddDeliveries")
    ),
    layout: 2,
  },
  {
    path: "/cricket/commentry/update-delivery",
    component: lazy(() =>
      import("./views/panels/cricket/match/commentary/Commentary1")
    ),
    layout: 2,
  },
  {
    path: "cricket/match/commentry/update-comment",
    component: lazy(() =>
      import("./views/panels/cricket/match/commentary/UpdateComment")
    ),
    layout: 2,
  },
  {
    path: "cricket/match/commentry/new-comment",
    component: lazy(() =>
      import("./views/panels/cricket/match/commentary/NewComment")
    ),
    layout: 2,
  },

  //#endregion match

  //#region cricket ranking
  {
    path: "/cricket/ranking",
    component: lazy(() => import("./views/panels/cricket/ranking/Ranking")),
    layout: 1,
  },
  {
    path: "/cricket/ranking/detail",
    component: lazy(() =>
      import("./views/panels/cricket/ranking/RankingDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/cricket/ranking/add",
    component: lazy(() => import("./views/panels/cricket/ranking/RankingForm")),
    layout: 1,
  },
  {
    path: "/cricket/ranking/update",
    component: lazy(() => import("./views/panels/cricket/ranking/RankingForm")),
    layout: 1,
  },

  // {
  //   path: "/cricket/match-detail",
  //   component: lazy(() =>
  //     import("./views/panels/cricket/match/MatchDetailPage")
  //   ),
  //   layout: 1,
  // },

  {
    path: "/cricket/commentary-options",
    component: lazy(() =>
      import(
        "./views/panels/cricket/match/commentary/commentaryOptions/CommentaryOptions"
      )
    ),
    layout: 1,
  },

  {
    path: "/cricket/match-add-detail/:matchId/:homeId/:awayId/:inningsId",
    component: lazy(() =>
      import("./views/panels/cricket/match/MatchAddDetailPage")
    ),
    layout: 2,
  },
  {
    path: "/cricket/scorecard-page/:matchId/:homeId/:awayId/:inningsId",
    component: lazy(() =>
      import("./views/panels/cricket/match/scorecard/Scorecard")
    ),
    layout: 2,
  },

  //#endregion Cricket Routes - end

  //#region System Routes - start
  {
    path: "/country",
    component: lazy(() => import("./views/panels/others/country/Country")),
    layout: 1,
  },
  {
    path: "/seasons",
    component: lazy(() => import("./views/panels/others/season/Season")),
    layout: 1,
  },
  {
    path: "/promotions",
    component: lazy(() => import("./views/panels/others/promotion/Promotion")),
    layout: 1,
  },

  // Section Football

  //#region Tournament Start
  // {
  //   path: "/football/matches/testing",
  //   component: lazy(() =>
  //     import("./views/panels/football/match/TestMatches")
  //   ),
  //   layout: 1,
  // },
  {
    path: "/football/tournament",
    component: lazy(() =>
      import("./views/panels/football/tournament/Tournament")
    ),
    layout: 1,
  },
  {
    path: "/football/tournament/detail",
    component: lazy(() =>
      import("./views/panels/football/tournament/TournamentDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/football/tournament/add",
    component: lazy(() =>
      import("./views/panels/football/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/football/tournament/update",
    component: lazy(() =>
      import("./views/panels/football/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/football/country-tournaments",
    component: lazy(() =>
      import("./views/panels/football/tournament/CountryTournament")
    ),
    layout: 1,
  },
  //#endregion Tournament end

  //#region contest
  {
    path: "/football/contest",
    component: lazy(() => import("./views/panels/football/contest/Contest")),
    layout: 1,
  },
  {
    path: "/football/contest/detail",
    component: lazy(() =>
      import("./views/panels/football/contest/ContestDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/football/contest/add",
    component: lazy(() =>
      import("./views/panels/football/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/football/contest/update",
    component: lazy(() =>
      import("./views/panels/football/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/football/contest/add-competitor",
    component: lazy(() =>
      import("./views/panels/football/contest/AddParticipant")
    ),
    layout: 1,
  },
  {
    path: "/football/contest/add-rounds",
    component: lazy(() => import("./views/panels/football/contest/AddRounds")),
    layout: 1,
  },
  {
    path: "/football/contest/matches",
    component: lazy(() =>
      import("./views/panels/football/contest/ContestMatches")
    ),
    layout: 1,
  },
  {
    path: "/football/contest/matches/scrapper",
    component: lazy(() =>
      import("./features/matchesscrapper/MatchesScrapperPage")
    ),
    layout: 1,
  },
  {
    path: "/football/contest/competitors/scrapper",
    component: lazy(() =>
      import("./features/competitorsscrapper/CompetitorScrapper")
    ),
    layout: 1,
  },
  {
    path: "/football/contest/matches-comparison",
    component: lazy(() =>
      import("./views/panels/football/contest/EnetMatches")
    ),
    layout: 1,
  },
  {
    path: "/football/contest/matches-sw-comparison",
    component: lazy(() =>
      import("./views/panels/football/contest/SoccerwayMatches")
    ),
    layout: 1,
  },

  //#endregion contest end

  //#region competitor
  {
    path: "/football/competitor",
    component: lazy(() =>
      import("./views/panels/football/competitor/Competitor")
    ),
    layout: 1,
  },
  {
    path: "/football/competitor/detail",
    component: lazy(() =>
      import("./views/panels/football/competitor/CompetitorDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/football/competitor/update",
    component: lazy(() =>
      import("./views/panels/football/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/football/competitor/add",
    component: lazy(() =>
      import("./views/panels/football/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/football/competitor/add-coach",
    component: lazy(() =>
      import("./views/panels/football/competitor/AssignCoachToCompForm")
    ),
    layout: 1,
  },
  {
    path: "/football/competitor/add-competitor-to-contest",
    component: lazy(() =>
      import("./views/panels/football/competitor/AddCompToContestForm")
    ),
    layout: 1,
  },
  {
    path: "/football/competitor/add-squad",
    component: lazy(() =>
      import("./views/panels/football/competitor/AddCompetitorSquad")
    ),
    layout: 1,
  },
  {
    path: "/football/competitor/sofa-squad",
    component: lazy(() =>
      import("./views/panels/football/competitor/SofaSquad")
    ),
    layout: 1,
  },
 
  {
    path: "/football/competitor-matches",
    component: lazy(() =>
      import("./views/panels/football/competitor/CompetitorMatches")
    ),
    layout: 1,
  },

  //#endregion competitor end

  //#region player
  {
    path: "/football/player",
    component: lazy(() => import("./views/panels/football/player/Player")),
    layout: 1,
  },
  {
    path: "/football/player/detail",
    component: lazy(() =>
      import("./views/panels/football/player/PlayerDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/football/player/update",
    component: lazy(() => import("./views/panels/football/player/PlayerForm")),
    layout: 1,
  },
  {
    path: "/football/player/add",
    component: lazy(() => import("./views/panels/football/player/PlayerForm")),
    layout: 1,
  },
  {
    path: "/football/player/transfer",
    component: lazy(() =>
      import("./views/panels/football/player/PlayerTransferForm")
    ),
    layout: 1,
  },
  {
    path: "/football/player/squad",
    component: lazy(() =>
      import("./views/panels/football/player/AddPlayerToComp")
    ),
    layout: 1,
  },
  //#endregion player end

  //#region official
  {
    path: "/football/official",
    component: lazy(() => import("./views/panels/football/official/Official")),
    layout: 1,
  },
  {
    path: "/football/official/detail",
    component: lazy(() =>
      import("./views/panels/football/official/OfficialDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/football/official/add",
    component: lazy(() =>
      import("./views/panels/football/official/OfficialForm")
    ),
    layout: 1,
  },
  {
    path: "/football/official/update",
    component: lazy(() =>
      import("./views/panels/football/official/OfficialForm")
    ),
    layout: 1,
  },
  //#endregion official

  //#region venue
  {
    path: "/football/venue",
    component: lazy(() => import("./views/panels/football/venue/Venue")),
    layout: 1,
  },
  {
    path: "/football/venue/detail",
    component: lazy(() =>
      import("./views/panels/football/venue/VenueDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/football/venue/add",
    component: lazy(() => import("./views/panels/football/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/football/venue/update",
    component: lazy(() => import("./views/panels/football/venue/VenueForm")),
    layout: 1,
  },
  //#endregion venue

  //#region coach
  {
    path: "/football/coach",
    component: lazy(() =>
      import("./views/panels/football/coach/FootballCoach")
    ),
    layout: 1,
  },
  {
    path: "/football/coach/detail",
    component: lazy(() =>
      import("./views/panels/football/coach/CoachDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/football/coach/add",
    component: lazy(() => import("./views/panels/football/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/football/coach/update",
    component: lazy(() => import("./views/panels/football/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/football/coach/add-role",
    component: lazy(() =>
      import("./views/panels/football/coach/CoachRoleForm")
    ),
    layout: 1,
  },
  //#endregion coach

  //#region match
  {
    path: "/football/match/testing",
    component: lazy(() =>
      import("./views/panels/football/match/FootballMatchList")
    ),
    layout: 1,
  },
  {
    path: "/football/match",
    component: lazy(() =>
      import("./views/panels/football/match/FootballMatches")
    ),
    layout: 1,
  },
  {
    path: "/football/match/add",
    component: lazy(() => import("./views/panels/football/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/football/match/update",
    component: lazy(() => import("./views/panels/football/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/football/match/player-of-match",
    component: lazy(() =>
      import("./views/panels/football/match/PlayerOfMatchForm")
    ),
    layout: 1,
  },
  {
    path: "/football/match/official",
    component: lazy(() =>
      import("./views/panels/football/match/MatchOfficialForm")
    ),
    layout: 1,
  },
  {
    path: "/football/match/lineup",
    component: lazy(() =>
      import("./views/panels/football/match/lineup/MatchLineUps")
    ),
    layout: 1,
  },
  {
    path: "/football/match/sofa-lineups",
    component: lazy(() =>
      import("./views/panels/football/match/lineup/SofaLineupsPage")
    ),
    layout: 1,
  },
  {
    path: "/football/match/goalcardsub/",
    component: lazy(() =>
      import("./views/panels/football/match/goalscardssubs/GoalsCardsSubs")
    ),
    layout: 1,
  },
  {
    path: "/football/myMatches",
    component: lazy(() => import("./views/panels/football/match/FavMatches")),
    layout: 1,
  },
  {
    path: "/football/match/stats",
    component: lazy(() => import("./views/panels/football/match/MatchStats")),
    layout: 1,
  },
  {
    path: "/football/match/scores",
    component: lazy(() =>
      import("./views/panels/football/match/scores/MatchScores")
    ),
    layout: 1,
  },
  {
    path: "/football/player/statistics",
    component: lazy(() =>
      import("./views/panels/football/player/playerStatisticsForm")
    ),
    layout: 1,
  },
  {
    path: "/football/match/channel/add",
    component: lazy(() =>
      import("./views/panels/football/match/channel/MatchChannelForm")
    ),
    layout: 1,
  },
  {
    path: "/football/match/channel/update",
    component: lazy(() =>
      import("./views/panels/football/match/channel/MatchChannelForm")
    ),
    layout: 1,
  },
  {
    path: "/football/match-mapping",
    component: lazy(() =>
      import("./views/panels/football/match/matchmapping/MatchMapping")
    ),
    layout: 1,
  },
  {
    path: "/football/provider-search",
    component: lazy(() =>
      import("./views/panels/football/providersearch/ProviderSearch.jsx")
    ),
    layout: 1,
  },
  // {
  //   path: "/football/match/channel",
  //   component: lazy(() =>
  //     import("./views/panels/football/match/channel/MatchChannel")
  //   ),
  //   layout: 1,
  // },
  {
    path: "/football/match/textComment",
    component: lazy(() =>
      import("./views/panels/football/match/comment/TextCommentList")
    ),
    layout: 1,
  },
  {
    path: "/football/match/textComment/add",
    component: lazy(() =>
      import("./views/panels/football/match/comment/TextCommentForm")
    ),
    layout: 1,
  },
  {
    path: "/football/match/minutes",
    component: lazy(() =>
      import("./views/panels/football/match/matchMinutes/MatchMinutesList")
    ),
    layout: 1,
  },
  {
    path: "/football/match/minute/add",
    component: lazy(() =>
      import("./views/panels/football/match/matchMinutes/MatchMinutesForm")
    ),
    layout: 1,
  },
  //#endregion match

  //#region League Table

  {
    path: "/football/contest/league-table",
    component: lazy(() =>
      import("./views/panels/football/contest/leagueTable/LeagueTable")
    ),
    layout: 1,
  },
  //#endregion

  // Section Football Ends

  // Section Football Ends

  //#region Tennis Start
  //#region Tennis Tournament Routes
  {
    path: "/tennis/contest/matches-comparison",
    component: lazy(() =>
      import("./views/panels/tennis/contest/EnetMatches")
    ),
    layout: 1,
  },
  {
    path: "/tennis/match-mapping",
    component: lazy(() =>
      import("./views/panels/tennis/match/matchmapping/MatchMapping")
    ),
    layout: 1,
  },
  {
    path: "/tennis/provider-search",
    component: lazy(() =>
      import("./views/panels/tennis/providersearch/ProviderSearch.jsx")
    ),
    layout: 1,
  },
  {
    path: "/tennis/tournament",
    component: lazy(() =>
      import("./views/panels/tennis/tournament/Tournament")
    ),
    layout: 1,
  },
  {
    path: "/tennis/tournament/detail",
    component: lazy(() =>
      import("./views/panels/tennis/tournament/TournamentDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/tennis/tournament/add",
    component: lazy(() =>
      import("./views/panels/tennis/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/tennis/tournament/update",
    component: lazy(() =>
      import("./views/panels/tennis/tournament/TournamentForm")
    ),
    layout: 1,
  },

  //#endregion

  //#region Tennis Contest Routes
  {
    path: "/tennis/contest",
    component: lazy(() => import("./views/panels/tennis/contest/Contest")),
    layout: 1,
  },
  {
    path: "/tennis/contest/detail",
    component: lazy(() =>
      import("./views/panels/tennis/contest/ContestDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/tennis/contest/add",
    component: lazy(() => import("./views/panels/tennis/contest/ContestForm")),
    layout: 1,
  },
  {
    path: "/tennis/contest/update",
    component: lazy(() => import("./views/panels/tennis/contest/ContestForm")),
    layout: 1,
  },
  {
    path: "/tennis/contest/add-participant",
    component: lazy(() =>
      import("./views/panels/tennis/contest/participants/AddParticipant")
    ),
    layout: 1,
  },
  {
    path: "/tennis/contest/add-round",
    component: lazy(() => import("./features/contest/AddRounds")),
    layout: 1,
  },
  {
    path: "/tennis/contest-matches",
    component: lazy(() =>
      import("./views/panels/tennis/contest/ContestMatches")
    ),
    layout: 1,
  },
  {
    path: "/tennis/contest/league-table",
    component: lazy(() =>
      import("./views/panels/tennis/contest/leagueTable/LeagueTable")
    ),
    layout: 1,
  },
  {
    path: "/tennis/contest/matches/scrapper",
    component: lazy(() =>
      import("./features/matchesscrapper/MatchesScrapperPage")
    ),
    layout: 1,
  },
  {
    path: "/tennis/contest/competitors/scrapper",
    component: lazy(() =>
      import("./features/competitorsscrapper/CompetitorScrapper")
    ),
    layout: 1,
  },
  //#endregion contest end

  //#region Tennis Double Routes
  {
    path: "/tennis/double",
    component: lazy(() => import("./views/panels/tennis/double/Doubles")),
    layout: 1,
  },
  {
    path: "/tennis/double/detail",
    component: lazy(() =>
      import("./views/panels/tennis/double/DoubleDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/tennis/double/add",
    component: lazy(() => import("./views/panels/tennis/double/DoubleForm")),
    layout: 1,
  },
  {
    path: "/tennis/double/update",
    component: lazy(() => import("./views/panels/tennis/double/DoubleForm")),
    layout: 1,
  },
  {
    path: "/tennis/double/add-to-contest",
    component: lazy(() => import("./views/panels/tennis/double/AddToContest")),
    layout: 1,
  },

  //#endregion

  //#region Tennis Team Routes
  {
    path: "/tennis/team",
    component: lazy(() => import("./views/panels/tennis/team/Team")),
    layout: 1,
  },
  {
    path: "/tennis/team/detail",
    component: lazy(() => import("./views/panels/tennis/team/TeamDetailModal")),
    layout: 1,
  },
  {
    path: "/tennis/team/add",
    component: lazy(() => import("./views/panels/tennis/team/TeamForm")),
    layout: 1,
  },
  {
    path: "/tennis/team/update",
    component: lazy(() => import("./views/panels/tennis/team/TeamForm")),
    layout: 1,
  },
  {
    path: "/tennis/team/add-to-contest",
    component: lazy(() => import("./views/panels/tennis/team/AddToContest")),
    layout: 1,
  },

  //#endregion

  //#region Tennis Players Routes
  {
    path: "/tennis/player",
    component: lazy(() => import("./views/panels/tennis/player/Player")),
    layout: 1,
  },
  {
    path: "/tennis/player/detail",
    component: lazy(() =>
      import("./views/panels/tennis/player/PlayerDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/tennis/player/add",
    component: lazy(() => import("./views/panels/tennis/player/PlayerForm")),
    layout: 1,
  },
  {
    path: "/tennis/player/update",
    component: lazy(() => import("./views/panels/tennis/player/PlayerForm")),
    layout: 1,
  },

  //#endregion

  //#region Tennis Venues Routes
  {
    path: "/tennis/venue",
    component: lazy(() => import("./views/panels/tennis/venue/Venue")),
    layout: 1,
  },
  {
    path: "/tennis/venue/detail",
    component: lazy(() =>
      import("./views/panels/tennis/venue/VenueDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/tennis/venue/add",
    component: lazy(() => import("./views/panels/tennis/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/tennis/venue/update",
    component: lazy(() => import("./views/panels/tennis/venue/VenueForm")),
    layout: 1,
  },

  //#endregion

  //#region Tennis officials Routes
  {
    path: "/tennis/official",
    component: lazy(() => import("./views/panels/tennis/official/Official")),
    layout: 1,
  },
  {
    path: "/tennis/official/detail",
    component: lazy(() =>
      import("./views/panels/tennis/official/OfficialDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/tennis/official/add",
    component: lazy(() =>
      import("./views/panels/tennis/official/OfficialForm")
    ),
    layout: 1,
  },
  {
    path: "/tennis/official/update",
    component: lazy(() =>
      import("./views/panels/tennis/official/OfficialForm")
    ),
    layout: 1,
  },
  //#endregion official

  //#region tennis coach
  {
    path: "/tennis/coach",
    component: lazy(() => import("./views/panels/tennis/coach/TennisCoach")),
    layout: 1,
  },
  {
    path: "/tennis/coach/detail",
    component: lazy(() =>
      import("./views/panels/tennis/coach/CoachDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/tennis/coach/add",
    component: lazy(() => import("./views/panels/tennis/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/tennis/coach/update",
    component: lazy(() => import("./views/panels/tennis/coach/CoachForm")),
    layout: 1,
  },
  //#endregion coach

  //#region tennis Matches
  {
    path: "/tennis/match",
    component: lazy(() => import("./views/panels/tennis/match/TennisMatches")),
    layout: 1,
  },
  {
    path: "/tennis/match/stats",
    component: lazy(() => import("./views/panels/tennis/match/MatchStats")),
    layout: 1,
  },
  {
    path: "/tennis/match/add",
    component: lazy(() => import("./views/panels/tennis/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/tennis/match/update",
    component: lazy(() => import("./views/panels/tennis/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/tennis/match/scores",
    component: lazy(() =>
      import("./views/panels/tennis/match/scores/MatchScores")
    ),
    layout: 1,
  },
  //#endregion

  //#region tennis Matches
  {
    path: "/tennis/team-matches",
    component: lazy(() =>
      import("./views/panels/tennis/teamMatches/TeamMatches")
    ),
    layout: 1,
  },
  {
    path: "/tennis/team-matches/add",
    component: lazy(() =>
      import("./views/panels/tennis/teamMatches/MatchForm")
    ),
    layout: 1,
  },
  {
    path: "/tennis/team-matches/update",
    component: lazy(() =>
      import("./views/panels/tennis/teamMatches/MatchForm")
    ),
    layout: 1,
  },
  {
    path: "/tennis/team-matches/add-squad",
    component: lazy(() =>
      import("./views/panels/tennis/teamMatches/TeamMatchSquad")
    ),
    layout: 1,
  },
  //#endregion tennis Matches

  //#region Tennis Ranking
  {
    path: "/tennis/ranking",
    component: lazy(() =>
      import("./views/panels/tennis/ranking/TennisRanking")
    ),
    layout: 1,
  },
  {
    path: "/tennis/ranking/add",
    component: lazy(() => import("./views/panels/tennis/ranking/RankingForm")),
    layout: 1,
  },
  {
    path: "/tennis/ranking/update",
    component: lazy(() => import("./views/panels/tennis/ranking/RankingForm")),
    layout: 1,
  },
  //#endregion Tennis Ranking

  //#endregion Tennis

  //#region basketball tournament

  {
    path: "/basketball/tournament",
    component: lazy(() =>
      import("./views/panels/basketball/tournament/Tournament")
    ),
    layout: 1,
  },
  {
    path: "/basketball/tournament/add",
    component: lazy(() =>
      import("./views/panels/basketball/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/tournament/update",
    component: lazy(() =>
      import("./views/panels/basketball/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/tournament/detail",
    component: lazy(() =>
      import("./views/panels/basketball/tournament/TournamentDetailModal")
    ),
    layout: 1,
  },
  //#region player
  {
    path: "/basketball/player",
    component: lazy(() => import("./views/panels/basketball/player/Player")),
    layout: 1,
  },
  {
    path: "/basketball/player/detail",
    component: lazy(() =>
      import("./views/panels/basketball/player/PlayerDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/basketball/player/update",
    component: lazy(() =>
      import("./views/panels/basketball/player/PlayerForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/player/add",
    component: lazy(() =>
      import("./views/panels/basketball/player/PlayerForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/player/transfer",
    component: lazy(() =>
      import("./views/panels/basketball/player/PlayerTransferForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/player/statistics",
    component: lazy(() =>
      import("./views/panels/basketball/player/playerStatisticsForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/player/squad",
    component: lazy(() =>
      import("./views/panels/basketball/player/AddPlayerToComp")
    ),
    layout: 1,
  },
  //#endregion player end

  //Competitor
  {
    path: "/basketball/competitor",
    component: lazy(() =>
      import("./views/panels/basketball/competitor/Competitor")
    ),
    layout: 1,
  },
  {
    path: "/basketball/competitor/add",
    component: lazy(() =>
      import("./views/panels/basketball/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/competitor/update",
    component: lazy(() =>
      import("./views/panels/basketball/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/competitor/add-competitor-to-contest",
    component: lazy(() =>
      import("./views/panels/basketball/competitor/AddCompToContestForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/competitor/add-coach",
    component: lazy(() =>
      import("./views/panels/basketball/competitor/AssignCoachToCompForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/competitor/detail",
    component: lazy(() =>
      import("./views/panels/basketball/competitor/CompetitorDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/basketball/competitor/add-squad",
    component: lazy(() =>
      import("./views/panels/basketball/competitor/AddCompetitorSquad")
    ),
    layout: 1,
  },

  //#region coach
  {
    path: "/basketball/coach",
    component: lazy(() =>
      import("./views/panels/basketball/coach//BasketballCoach")
    ),
    layout: 1,
  },
  {
    path: "/basketball/coach/detail",
    component: lazy(() =>
      import("./views/panels/basketball/coach/CoachDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/basketball/coach/add",
    component: lazy(() => import("./views/panels/basketball/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/basketball/coach/update",
    component: lazy(() => import("./views/panels/basketball/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/basketball/coach/add-role",
    component: lazy(() =>
      import("./views/panels/basketball/coach/CoachRoleForm")
    ),
    layout: 1,
  },
  //#endregion coach
  //#region contest
  {
    path: "/basketball/contest",
    component: lazy(() => import("./views/panels/basketball/contest/Contest")),
    layout: 1,
  },
  {
    path: "/basketball/contest/update",
    component: lazy(() =>
      import("./views/panels/basketball/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/contest/add",
    component: lazy(() =>
      import("./views/panels/basketball/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/contest/add-competitor",
    component: lazy(() =>
      import("./views/panels/basketball/contest/AddParticipant")
    ),
    layout: 1,
  },
  {
    path: "/basketball/contest/detail",
    component: lazy(() =>
      import("./views/panels/basketball/contest/ContestDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/basketball/contest/matches",
    component: lazy(() =>
      import("./views/panels/basketball/contest/ContestMatches")
    ),
    layout: 1,
  },
  {
    path: "/basketball/contest/league-table",
    component: lazy(() =>
      import("./views/panels/basketball/contest/LeagueTable")
    ),
    layout: 1,
  },
  {
    path: "/basketball/contest/add-rounds",
    component: lazy(() => import("./features/contest/AddRounds")),
    layout: 1,
  },
  //End Contest
  //#region official
  {
    path: "/basketball/official",
    component: lazy(() =>
      import("./views/panels/basketball/official/Official")
    ),
    layout: 1,
  },
  {
    path: "/basketball/official/detail",
    component: lazy(() =>
      import("./views/panels/basketball/official/OfficialDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/basketball/official/add",
    component: lazy(() =>
      import("./views/panels/basketball/official/OfficialForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/official/update",
    component: lazy(() =>
      import("./views/panels/basketball/official/OfficialForm")
    ),
    layout: 1,
  },
  //#endregion official
  //#region venue
  {
    path: "/basketball/venue",
    component: lazy(() => import("./views/panels/basketball/venue/Venue")),
    layout: 1,
  },
  {
    path: "/basketball/venue/detail",
    component: lazy(() =>
      import("./views/panels/basketball/venue/VenueDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/basketball/venue/add",
    component: lazy(() => import("./views/panels/basketball/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/basketball/venue/update",
    component: lazy(() => import("./views/panels/basketball/venue/VenueForm")),
    layout: 1,
  },
  //#endregion venue
  //#region match
  {
    path: "/basketball/match",
    component: lazy(() => import("./views/panels/basketball/match/Match")),
    layout: 1,
  },
  {
    path: "/basketball/match/add",
    component: lazy(() => import("./views/panels/basketball/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/basketball/match/update",
    component: lazy(() => import("./views/panels/basketball/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/basketball/match/player-of-match",
    component: lazy(() =>
      import("./views/panels/basketball/match/PlayerOfMatchForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/match/official",
    component: lazy(() =>
      import("./views/panels/basketball/match/MatchOfficialForm")
    ),
    layout: 1,
  },
  {
    path: "/basketball/match/lineup",
    component: lazy(() =>
      import("./views/panels/basketball/match/lineup/MatchLineUps")
    ),
    layout: 1,
  },
  {
    path: "/basketball/match/goalcardsub/",
    component: lazy(() =>
      import("./views/panels/basketball/match/goalscardssubs/GoalsCardsSubs")
    ),
    layout: 1,
  },
  {
    path: "/basketball/match/stats",
    component: lazy(() => import("./views/panels/basketball/match/MatchStats")),
    layout: 1,
  },
  {
    path: "/basketball/match/scores",
    component: lazy(() =>
      import("./views/panels/basketball/match/scores/MatchScores")
    ),
    layout: 1,
  },
  //#endregion match

  // Hockey Sport Start

  {
    path: "/hockey/tournament",
    component: lazy(() =>
      import("./views/panels/ice_hockey/tournament/Tournament")
    ),
    layout: 1,
  },
  {
    path: "/hockey/tournament/detail",
    component: lazy(() =>
      import("./views/panels/ice_hockey/tournament/TournamentDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/hockey/tournament/add",
    component: lazy(() =>
      import("./views/panels/ice_hockey/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/hockey/tournament/update",
    component: lazy(() =>
      import("./views/panels/ice_hockey/tournament/TournamentForm")
    ),
    layout: 1,
  },
  //Competitor
  {
    path: "/hockey/competitor",
    component: lazy(() =>
      import("./views/panels/ice_hockey/competitor/Competitor")
    ),
    layout: 1,
  },
  {
    path: "/hockey/competitor/detail",
    component: lazy(() =>
      import("./views/panels/ice_hockey/competitor/CompetitorDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/hockey/competitor/add",
    component: lazy(() =>
      import("./views/panels/ice_hockey/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/hockey/competitor/update",
    component: lazy(() =>
      import("./views/panels/ice_hockey/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/hockey/competitor/add-competitor-to-contest",
    component: lazy(() =>
      import("./views/panels/ice_hockey/competitor/AddCompToContestForm")
    ),
    layout: 1,
  },
  {
    path: "/hockey/competitor/add-coach",
    component: lazy(() =>
      import("./views/panels/ice_hockey/competitor/AssignCoachToCompForm")
    ),
    layout: 1,
  },

  //#region contest
  {
    path: "/hockey/contest",
    component: lazy(() => import("./views/panels/ice_hockey/contest/Contest")),
    layout: 1,
  },
  {
    path: "/hockey/contest/update",
    component: lazy(() =>
      import("./views/panels/ice_hockey/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/hockey/contest/add",
    component: lazy(() =>
      import("./views/panels/ice_hockey/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/hockey/contest/add-competitor",
    component: lazy(() =>
      import("./views/panels/ice_hockey/contest/AddParticipant")
    ),
    layout: 1,
  },
  {
    path: "/hockey/contest/detail",
    component: lazy(() =>
      import("./views/panels/ice_hockey/contest/ContestDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/hockey/contest/add-rounds",
    component: lazy(() => import("./features/contest/AddRounds")),
    layout: 1,
  },
  {
    path: "/hockey/contest-matches",
    component: lazy(() =>
      import("./views/panels/ice_hockey/contest/ContestMatches")
    ),
    layout: 1,
  },
  //End Contest
  //#region League Table

  {
    path: "/hockey/contest/league-table",
    component: lazy(() =>
      import("./views/panels/ice_hockey/contest/LeagueTable")
    ),
    layout: 1,
  },
  //#endregion.
  //#region player
  {
    path: "/hockey/player",
    component: lazy(() => import("./views/panels/ice_hockey/player/Player")),
    layout: 1,
  },
  {
    path: "/hockey/player/detail",
    component: lazy(() =>
      import("./views/panels/ice_hockey/player/PlayerDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/hockey/player/update",
    component: lazy(() =>
      import("./views/panels/ice_hockey/player/PlayerForm")
    ),
    layout: 1,
  },
  {
    path: "/hockey/player/add",
    component: lazy(() =>
      import("./views/panels/ice_hockey/player/PlayerForm")
    ),
    layout: 1,
  },
  {
    path: "/hockey/player/transfer",
    component: lazy(() =>
      import("./views/panels/ice_hockey/player/PlayerTransferForm")
    ),
    layout: 1,
  },
  {
    path: "/hockey/player/statistics",
    component: lazy(() =>
      import("./views/panels/ice_hockey/player/playerStatisticsForm")
    ),
    layout: 1,
  },
  {
    path: "/hockey/player/squad",
    component: lazy(() =>
      import("./views/panels/ice_hockey/player/AddPlayerToComp")
    ),
    layout: 1,
  },
  //#endregion player end
  //#region match
  {
    path: "/hockey/match",
    component: lazy(() =>
      import("./views/panels/ice_hockey/match/IceHockeyMatches")
    ),
    layout: 1,
  },
  {
    path: "/hockey/match/add",
    component: lazy(() => import("./views/panels/ice_hockey/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/hockey/match/update",
    component: lazy(() => import("./views/panels/ice_hockey/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/hockey/match/player-of-match",
    component: lazy(() =>
      import("./views/panels/ice_hockey/match/PlayerOfMatchForm")
    ),
    layout: 1,
  },
  {
    path: "/hockey/match/official",
    component: lazy(() =>
      import("./views/panels/ice_hockey/match/MatchOfficialForm")
    ),
    layout: 1,
  },
  {
    path: "/hockey/match/lineup",
    component: lazy(() =>
      import("./views/panels/ice_hockey/match/lineup/MatchLineUps")
    ),
    layout: 1,
  },
  // {
  //   path: "/hockey/match/lineup",
  //   component: lazy(() =>
  //     import("./views/panels/ice_hockey/match/lineup/LineupPlayerForm")
  //   ),
  //   layout: 1,
  // },
  {
    path: "/hockey/match/goalcardsub/",
    component: lazy(() =>
      import("./views/panels/ice_hockey/match/goalscardssubs/GoalsCardsSubs")
    ),
    layout: 1,
  },
  // {
  //   path: "/hockey/myMatches",
  //   component: lazy(() => import("./views/panels/ice_hockey/match/FavMatches")),
  //   layout: 1,
  // },
  {
    path: "/hockey/match/stats",
    component: lazy(() => import("./views/panels/ice_hockey/match/MatchStats")),
    layout: 1,
  },
  {
    path: "/hockey/match/scores",
    component: lazy(() =>
      import("./views/panels/ice_hockey/match/scores/MatchScores")
    ),
    layout: 1,
  },

  //#endregion match

  //#region official
  {
    path: "/hockey/official",
    component: lazy(() =>
      import("./views/panels/ice_hockey/official/Official")
    ),
    layout: 1,
  },
  {
    path: "/hockey/official/detail",
    component: lazy(() =>
      import("./views/panels/ice_hockey/official/OfficialDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/hockey/official/add",
    component: lazy(() =>
      import("./views/panels/ice_hockey/official/OfficialForm")
    ),
    layout: 1,
  },
  {
    path: "/hockey/official/update",
    component: lazy(() =>
      import("./views/panels/ice_hockey/official/OfficialForm")
    ),
    layout: 1,
  },
  //#endregion official
  //#region venue
  {
    path: "/hockey/venue",
    component: lazy(() => import("./views/panels/ice_hockey/venue/Venue")),
    layout: 1,
  },
  {
    path: "/hockey/venue/detail",
    component: lazy(() =>
      import("./views/panels/ice_hockey/venue/VenueDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/hockey/venue/add",
    component: lazy(() => import("./views/panels/ice_hockey/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/hockey/venue/update",
    component: lazy(() => import("./views/panels/ice_hockey/venue/VenueForm")),
    layout: 1,
  },
  //#endregion venue

  //#region coach
  {
    path: "/hockey/coach",
    component: lazy(() =>
      import("./views/panels/ice_hockey/coach/HockeyCoach")
    ),
    layout: 1,
  },
  {
    path: "/hockey/coach/detail",
    component: lazy(() =>
      import("./views/panels/ice_hockey/coach/CoachDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/hockey/coach/add",
    component: lazy(() => import("./views/panels/ice_hockey/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/hockey/coach/update",
    component: lazy(() => import("./views/panels/ice_hockey/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/hockey/coach/add-role",
    component: lazy(() =>
      import("./views/panels/ice_hockey/coach/CoachRoleForm")
    ),
    layout: 1,
  },
  //#endregion coach
  {
    path: "/hockey/competitor/add-squad",
    component: lazy(() =>
      import("./views/panels/ice_hockey/competitor/AddCompetitorSquad")
    ),
    layout: 1,
  },

  // #startregion
  {
    path: "/handball/tournament",
    component: lazy(() =>
      import("./views/panels/handball/tournament/Tournament")
    ),
    layout: 1,
  },
  {
    path: "/handball/tournament/detail",
    component: lazy(() =>
      import("./views/panels/handball/tournament/TournamentDetail")
    ),
    layout: 1,
  },
  {
    path: "/handball/tournament/add",
    component: lazy(() =>
      import("./views/panels/handball/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/handball/tournament/update",
    component: lazy(() =>
      import("./views/panels/handball/tournament/TournamentForm")
    ),
    layout: 1,
  },
  // #endregion

  //#region coach
  {
    path: "/handball/coach",
    component: lazy(() =>
      import("./views/panels/handball/coach/HandballCoach")
    ),
    layout: 1,
  },
  {
    path: "/handball/coach/detail",
    component: lazy(() =>
      import("./views/panels/handball/coach/CoachDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/handball/coach/add",
    component: lazy(() => import("./views/panels/handball/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/handball/coach/update",
    component: lazy(() => import("./views/panels/handball/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/handball/coach/add-role",
    component: lazy(() =>
      import("./views/panels/handball/coach/CoachRoleForm")
    ),
    layout: 1,
  },
  //#endregion coach

  //#region contest
  {
    path: "/handball/contest",
    component: lazy(() => import("./views/panels/handball/contest/Contest")),
    layout: 1,
  },
  {
    path: "/handball/contest/detail",
    component: lazy(() =>
      import("./views/panels/handball/contest/ContestDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/handball/contest/add",
    component: lazy(() =>
      import("./views/panels/handball/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/handball/contest/update",
    component: lazy(() =>
      import("./views/panels/handball/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/handball/contest/add-competitor",
    component: lazy(() =>
      import("./views/panels/handball/contest/AddParticipant")
    ),
    layout: 1,
  },
  {
    path: "/handball/contest/add-rounds",
    component: lazy(() => import("./features/contest/AddRounds")),
    layout: 1,
  },
  {
    path: "/handball/contest/matches/scrapper",
    component: lazy(() =>
      import("./features/matchesscrapper/MatchesScrapperPage")
    ),
    layout: 1,
  },
  {
    path: "/handball/contest/competitors/scrapper",
    component: lazy(() =>
      import("./features/competitorsscrapper/CompetitorScrapper")
    ),
    layout: 1,
  },
  {
    path: "/handball/contest/matches",
    component: lazy(() =>
      import("./views/panels/handball/contest/ContestMatches")
    ),
    layout: 1,
  },
  {
    path: "/handball/contest/matches-fs-comparison",
    component: lazy(() =>
      import("./views/panels/handball/contest/FlashMatchesComparison")
    ),
    layout: 1,
  },

  // {
  //   path: "/football/contest-matches",
  //   component: lazy(() =>
  //     import("./views/panels/football/contest/ContestMatches")
  //   ),
  //   layout: 1,
  // },

  //#endregion contest end

  //#region competitor
  {
    path: "/handball/competitor",
    component: lazy(() =>
      import("./views/panels/handball/competitor/Competitor")
    ),
    layout: 1,
  },
  {
    path: "/handball/competitor/detail",
    component: lazy(() =>
      import("./views/panels/handball/competitor/CompetitorDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/handball/competitor/update",
    component: lazy(() =>
      import("./views/panels/handball/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/handball/competitor/add",
    component: lazy(() =>
      import("./views/panels/handball/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/handball/competitor/add-competitor-to-contest",
    component: lazy(() =>
      import("./views/panels/handball/competitor/AddCompToContestForm")
    ),
    layout: 1,
  },

  //#endregion competitor end

  //#region venue
  {
    path: "/handball/venue",
    component: lazy(() => import("./views/panels/handball/venue/Venue")),
    layout: 1,
  },
  {
    path: "/handball/venue/detail",
    component: lazy(() =>
      import("./views/panels/handball/venue/VenueDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/handball/venue/add",
    component: lazy(() => import("./views/panels/handball/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/handball/venue/update",
    component: lazy(() => import("./views/panels/handball/venue/VenueForm")),
    layout: 1,
  },
  //#endregion venue

  //#region Matches

  {
    path: "/handball/match",
    component: lazy(() =>
      import("./views/panels/handball/match/HandballMatches")
    ),
    layout: 1,
  },
  {
    path: "/handball/match/add",
    component: lazy(() => import("./views/panels/handball/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/handball/match/update",
    component: lazy(() => import("./views/panels/handball/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/handball/match/scores",
    component: lazy(() =>
      import("./views/panels/handball/match/scores/MatchScores")
    ),
    layout: 1,
  },

  //#endregion Matches
  //#region official
  {
    path: "/handball/official",
    component: lazy(() => import("./views/panels/handball/official/Official")),
    layout: 1,
  },
  {
    path: "/handball/official/detail",
    component: lazy(() =>
      import("./views/panels/handball/official/OfficialDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/handball/official/add",
    component: lazy(() =>
      import("./views/panels/handball/official/OfficialForm")
    ),
    layout: 1,
  },
  {
    path: "/handball/official/update",
    component: lazy(() =>
      import("./views/panels/handball/official/OfficialForm")
    ),
    layout: 1,
  },
  //#endregion official

  //#region player
  {
    path: "/handball/player",
    component: lazy(() => import("./views/panels/handball/player/Player")),
    layout: 1,
  },
  {
    path: "/handball/player/detail",
    component: lazy(() =>
      import("./views/panels/handball/player/PlayerDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/handball/player/update",
    component: lazy(() => import("./views/panels/handball/player/PlayerForm")),
    layout: 1,
  },
  {
    path: "/handball/player/add",
    component: lazy(() => import("./views/panels/handball/player/PlayerForm")),
    layout: 1,
  },
  //#endregion player end

  //#region League Table

  {
    path: "/handball/contest/league-table",
    component: lazy(() =>
      import("./views/panels/handball/contest/leagueTable/LeagueTable")
    ),
    layout: 1,
  },
  //#endregion

  // #startregion
  {
    path: "/volleyball/tournament",
    component: lazy(() =>
      import("./views/panels/volleyball/tournament/Tournament")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/tournament/detail",
    component: lazy(() =>
      import("./views/panels/volleyball/tournament/TournamentDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/tournament/add",
    component: lazy(() =>
      import("./views/panels/volleyball/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/tournament/update",
    component: lazy(() =>
      import("./views/panels/volleyball/tournament/TournamentForm")
    ),
    layout: 1,
  },
  // #endregion
  //#region contest
  {
    path: "/volleyball/contest",
    component: lazy(() => import("./views/panels/volleyball/contest/Contest")),
    layout: 1,
  },
  {
    path: "/volleyball/contest/detail",
    component: lazy(() =>
      import("./views/panels/volleyball/contest/ContestDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/contest/add",
    component: lazy(() =>
      import("./views/panels/volleyball/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/contest/update",
    component: lazy(() =>
      import("./views/panels/volleyball/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/contest/add-rounds",
    component: lazy(() => import("./features/contest/AddRounds")),
    layout: 1,
  },
  {
    path: "/volleyball/contest/add-competitor",
    component: lazy(() =>
      import("./views/panels/volleyball/contest/AddParticipant")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/competitor/add-competitor-to-contest",
    component: lazy(() =>
      import("./views/panels/volleyball/competitor/AddCompToContestForm")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/contest/league-table",
    component: lazy(() =>
      import("./views/panels/volleyball/contest/LeagueTable")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/contest/matches",
    component: lazy(() =>
      import("./views/panels/volleyball/contest/ContestMatches")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/contest/matches/scrapper",
    component: lazy(() =>
      import("./features/matchesscrapper/MatchesScrapperPage")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/contest/competitors/scrapper",
    component: lazy(() =>
      import("./features/competitorsscrapper/CompetitorScrapper")
    ),
    layout: 1,
  },
  //#endregion contest end

  //#region competitor
  {
    path: "/volleyball/competitor",
    component: lazy(() =>
      import("./views/panels/volleyball/competitor/Competitor")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/competitor/detail",
    component: lazy(() =>
      import("./views/panels/volleyball/competitor/CompetitorDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/competitor/update",
    component: lazy(() =>
      import("./views/panels/volleyball/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/competitor/add",
    component: lazy(() =>
      import("./views/panels/volleyball/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/competitor/add-competitor-to-contest",
    component: lazy(() =>
      import("./views/panels/volleyball/competitor/AddCompToContestForm")
    ),
    layout: 1,
  },

  //#region Matches

  // {
  //   path: "/volleyball/match",
  //   component: lazy(() => import("./views/panels/volleyball/match/Matches")),
  //   layout: 1,
  // },
  {
    path: "/volleyball/match",
    component: lazy(() =>
      import("./views/panels/volleyball/match/VolleyballMatches")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/match/add",
    component: lazy(() => import("./views/panels/volleyball/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/volleyball/match/update",
    component: lazy(() => import("./views/panels/volleyball/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/volleyball/match/scores",
    component: lazy(() =>
      import("./views/panels/volleyball/match/scores/MatchScores")
    ),
    layout: 1,
  },

  //#endregion Matches
  //#region venue
  {
    path: "/volleyball/venue",
    component: lazy(() => import("./views/panels/volleyball/venue/Venue")),
    layout: 1,
  },
  {
    path: "/volleyball/venue/detail",
    component: lazy(() =>
      import("./views/panels/volleyball/venue/VenueDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/volleyball/venue/add",
    component: lazy(() => import("./views/panels/volleyball/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/volleyball/venue/update",
    component: lazy(() => import("./views/panels/volleyball/venue/VenueForm")),
    layout: 1,
  },
  //#endregion venue

  //#region Feild Hockey

  //#region tournament
  {
    path: "/field-hockey/tournament",
    component: lazy(() =>
      import("./views/panels/fieldhockey/tournament/Tournament")
    ),
    layout: 1,
  },
  {
    path: "/field-hockey/tournament/add",
    component: lazy(() =>
      import("./views/panels/fieldhockey/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/field-hockey/tournament/update",
    component: lazy(() =>
      import("./views/panels/fieldhockey/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/field-hockey/tournament/detail",
    component: lazy(() =>
      import("./views/panels/fieldhockey/tournament/TournamentDetailModal")
    ),
    layout: 1,
  },
  //#endregion tournament

  //#region competitor
  {
    path: "/field-hockey/competitor",
    component: lazy(() =>
      import("./views/panels/fieldhockey/competitor/Competitor")
    ),
    layout: 1,
  },
  {
    path: "/field-hockey/competitor/detail",
    component: lazy(() =>
      import("./views/panels/fieldhockey/competitor/CompetitorDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/field-hockey/competitor/update",
    component: lazy(() =>
      import("./views/panels/fieldhockey/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/field-hockey/competitor/add",
    component: lazy(() =>
      import("./views/panels/fieldhockey/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/field-hockey/competitor/add-competitor-to-contest",
    component: lazy(() =>
      import("./views/panels/fieldhockey/competitor/AddCompToContestForm")
    ),
    layout: 1,
  },

  //#endregion competitor end

  //#region contest
  {
    path: "/field-hockey/contest",
    component: lazy(() => import("./views/panels/fieldhockey/contest/Contest")),
    layout: 1,
  },
  {
    path: "/field-hockey/contest/detail",
    component: lazy(() =>
      import("./views/panels/fieldhockey/contest/ContestDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/field-hockey/contest/add",
    component: lazy(() =>
      import("./views/panels/fieldhockey/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/field-hockey/contest/update",
    component: lazy(() =>
      import("./views/panels/fieldhockey/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/field-hockey/contest/matches",
    component: lazy(() =>
      import("./views/panels/fieldhockey/contest/ContestMatches")
    ),
    layout: 1,
  },
  {
    path: "/field-hockey/contest/add-competitor",
    component: lazy(() =>
      import("./views/panels/fieldhockey/contest/AddParticipant")
    ),
    layout: 1,
  },
  {
    path: "/field-hockey/contest/add-rounds",
    component: lazy(() =>
      import("./views/panels/fieldhockey/contest/rounds/AddRounds")
    ),
    layout: 1,
  },

  //#endregion contest end

  //#region venue
  {
    path: "/field-hockey/venue",
    component: lazy(() => import("./views/panels/fieldhockey/venue/Venue")),
    layout: 1,
  },
  {
    path: "/field-hockey/venue/detail",
    component: lazy(() =>
      import("./views/panels/fieldhockey/venue/VenueDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/field-hockey/venue/add",
    component: lazy(() => import("./views/panels/fieldhockey/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/field-hockey/venue/update",
    component: lazy(() => import("./views/panels/fieldhockey/venue/VenueForm")),
    layout: 1,
  },
  //#endregion venue

  //#region Matches

  {
    path: "/field-hockey/match",
    component: lazy(() => import("./views/panels/fieldhockey/match/Matches")),
    layout: 1,
  },
  {
    path: "/field-hockey/match/add",
    component: lazy(() => import("./views/panels/fieldhockey/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/field-hockey/match/update",
    component: lazy(() => import("./views/panels/fieldhockey/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/field-hockey/match/scores",
    component: lazy(() =>
      import("./views/panels/fieldhockey/match/scores/MatchScores")
    ),
    layout: 1,
  },

  //#endregion Matches

  //#region Snooker

  {
    path: "/snooker/tournament",
    component: lazy(() =>
      import("./views/panels/snooker/tournament/Tournament")
    ),
    layout: 1,
  },
  {
    path: "/snooker/tournament/add",
    component: lazy(() =>
      import("./views/panels/snooker/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/snooker/tournament/update",
    component: lazy(() =>
      import("./views/panels/snooker/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/snooker/tournament/detail",
    component: lazy(() =>
      import("./views/panels/snooker/tournament/TournamentDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/snooker/contest",
    component: lazy(() => import("./views/panels/snooker/contest/Contest")),
    layout: 1,
  },
  {
    path: "/snooker/contest/add",
    component: lazy(() => import("./views/panels/snooker/contest/ContestForm")),
    layout: 1,
  },
  {
    path: "/snooker/contest/update",
    component: lazy(() => import("./views/panels/snooker/contest/ContestForm")),
    layout: 1,
  },
  {
    path: "/snooker/contest/detail",
    component: lazy(() =>
      import("./views/panels/snooker/contest/ContestDetailModal")
    ),
    layout: 1,
  },
  // {
  //   path: "/snooker/player",
  //   component: lazy(() =>
  //     import("./views/panels/Snooker/player/Player")
  //   ),
  //   layout: 1,
  // },
  // {
  //   path: "/snooker/double",
  //   component: lazy(() =>
  //     import("./views/panels/Snooker/double/Double")
  //   ),
  //   layout: 1,
  // },
  {
    path: "/snooker/venue",
    component: lazy(() => import("./views/panels/snooker/venue/Venue")),
    layout: 1,
  },
  {
    path: "/snooker/venue/add",
    component: lazy(() => import("./views/panels/snooker/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/snooker/venue/update",
    component: lazy(() => import("./views/panels/snooker/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/snooker/venue/detail",
    component: lazy(() =>
      import("./views/panels/snooker/venue/VenueDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/snooker/contest/add-participant",
    component: lazy(() =>
      import("./views/panels/snooker/contest/participants/AddParticipant")
    ),
    layout: 1,
  },
  {
    path: "/snooker/contest/league-table",
    component: lazy(() =>
      import("./views/panels/snooker/contest/leagueTable/LeagueTable")
    ),
    layout: 1,
  },
  {
    path: "/snooker/rank",
    component: lazy(() =>
      import("./views/panels/snooker/ranking/SnookerRanking")
    ),
    layout: 1,
  },
  {
    path: "/snooker/ranking/add",
    component: lazy(() => import("./views/panels/snooker/ranking/RankingForm")),
    layout: 1,
  },
  {
    path: "/snooker/ranking/update",
    component: lazy(() => import("./views/panels/snooker/ranking/RankingForm")),
    layout: 1,
  },
  {
    path: "/snooker/official",
    component: lazy(() => import("./views/panels/snooker/official/Official")),
    layout: 1,
  },
  {
    path: "/snooker/official/detail",
    component: lazy(() =>
      import("./views/panels/snooker/official/OfficialDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/snooker/official/add",
    component: lazy(() =>
      import("./views/panels/snooker/official/OfficialForm")
    ),
    layout: 1,
  },
  {
    path: "/snooker/official/update",
    component: lazy(() =>
      import("./views/panels/snooker/official/OfficialForm")
    ),
    layout: 1,
  },
  {
    path: "/snooker/player",
    component: lazy(() => import("./views/panels/snooker/player/Player")),
    layout: 1,
  },
  {
    path: "/snooker/player/detail",
    component: lazy(() =>
      import("./views/panels/snooker/player/PlayerDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/snooker/player/add",
    component: lazy(() => import("./views/panels/snooker/player/PlayerForm")),
    layout: 1,
  },
  {
    path: "/snooker/player/update",
    component: lazy(() => import("./views/panels/snooker/player/PlayerForm")),
    layout: 1,
  },
  {
    path: "/snooker/double",
    component: lazy(() => import("./views/panels/snooker/double/Doubles")),
    layout: 1,
  },
  {
    path: "/snooker/double/detail",
    component: lazy(() =>
      import("./views/panels/snooker/double/DoubleDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/snooker/double/add",
    component: lazy(() => import("./views/panels/snooker/double/DoubleForm")),
    layout: 1,
  },
  {
    path: "/snooker/double/update",
    component: lazy(() => import("./views/panels/snooker/double/DoubleForm")),
    layout: 1,
  },
  {
    path: "/snooker/double/add-to-contest",
    component: lazy(() => import("./views/panels/snooker/double/AddToContest")),
    layout: 1,
  },
  {
    path: "/snooker/team",
    component: lazy(() => import("./views/panels/snooker/team/Team")),
    layout: 1,
  },
  {
    path: "/snooker/team/detail",
    component: lazy(() =>
      import("./views/panels/snooker/team/TeamDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/snooker/team/add",
    component: lazy(() => import("./views/panels/snooker/team/TeamForm")),
    layout: 1,
  },
  {
    path: "/snooker/team/update",
    component: lazy(() => import("./views/panels/snooker/team/TeamForm")),
    layout: 1,
  },
  {
    path: "/snooker/team/add-to-contest",
    component: lazy(() => import("./views/panels/snooker/team/AddToContest")),
    layout: 1,
  },
  {
    path: "/snooker/match",
    component: lazy(() =>
      import("./views/panels/snooker/match/SnookerMatches")
    ),
    layout: 1,
  },
  {
    path: "/snooker/match/add",
    component: lazy(() => import("./views/panels/snooker/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/snooker/match/update",
    component: lazy(() => import("./views/panels/snooker/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/snooker/match/scores",
    component: lazy(() =>
      import("./views/panels/snooker/match/score/MatchScores")
    ),
    layout: 1,
  },
  //#endregion

  //#region american-football

  //#region tournament
  {
    path: "/american-football/tournament",
    component: lazy(() =>
      import("./views/panels/americanFootball/tournament/Tournament")
    ),
    layout: 1,
  },
  {
    path: "/american-football/tournament/add",
    component: lazy(() =>
      import("./views/panels/americanFootball/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/american-football/tournament/update",
    component: lazy(() =>
      import("./views/panels/americanFootball/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/american-football/tournament/detail",
    component: lazy(() =>
      import("./views/panels/americanFootball/tournament/TournamentDetailModal")
    ),
    layout: 1,
  },
  //#endregion tournament

  //#region player
  {
    path: "/american-football/player",
    component: lazy(() =>
      import("./views/panels/americanFootball/player/Player")
    ),
    layout: 1,
  },
  {
    path: "/american-football/player/detail",
    component: lazy(() =>
      import("./views/panels/americanFootball/player/PlayerDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/american-football/player/update",
    component: lazy(() =>
      import("./views/panels/americanFootball/player/PlayerForm")
    ),
    layout: 1,
  },
  {
    path: "/american-football/player/add",
    component: lazy(() =>
      import("./views/panels/americanFootball/player/PlayerForm")
    ),
    layout: 1,
  },
  {
    path: "/american-football/player/transfer",
    component: lazy(() =>
      import("./views/panels/americanFootball/player/PlayerTransferForm")
    ),
    layout: 1,
  },
  {
    path: "/american-football/player/statistics",
    component: lazy(() =>
      import("./views/panels/americanFootball/player/PlayerStatisticsForm")
    ),
    layout: 1,
  },

  //#endregion player end

  //#region competitor
  {
    path: "/american-football/competitor",
    component: lazy(() =>
      import("./views/panels/americanFootball/competitor/Competitor")
    ),
    layout: 1,
  },
  {
    path: "/american-football/competitor/detail",
    component: lazy(() =>
      import("./views/panels/americanFootball/competitor/CompetitorDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/american-football/competitor/update",
    component: lazy(() =>
      import("./views/panels/americanFootball/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/american-football/competitor/add",
    component: lazy(() =>
      import("./views/panels/americanFootball/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/american-football/competitor/add-competitor-to-contest",
    component: lazy(() =>
      import("./views/panels/americanFootball/competitor/AddCompToContestForm")
    ),
    layout: 1,
  },
  {
    path: "/american-football/competitor/add-squad",
    component: lazy(() =>
      import("./views/panels/americanFootball/competitor/Squad")
    ),
    layout: 1,
  },
  {
    path: "/american-football/contest/competitors/scrapper",
    component: lazy(() =>
      import("./features/competitorsscrapper/CompetitorScrapper")
    ),
    layout: 1,
  },
  //#endregion competitor end

  //#region contest
  {
    path: "/american-football/contest",
    component: lazy(() =>
      import("./views/panels/americanFootball/contest/Contest")
    ),
    layout: 1,
  },
  {
    path: "/american-football/contest/detail",
    component: lazy(() =>
      import("./views/panels/americanFootball/contest/ContestDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/american-football/contest/add",
    component: lazy(() =>
      import("./views/panels/americanFootball/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/american-football/contest/update",
    component: lazy(() =>
      import("./views/panels/americanFootball/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/american-football/contest/matches",
    component: lazy(() =>
      import("./views/panels/americanFootball/contest/ContestMatches")
    ),
    layout: 1,
  },
  {
    path: "/american-football/contest/league-table",
    component: lazy(() =>
      import("./views/panels/americanFootball/contest/leagueTable/LeagueTable")
    ),
    layout: 1,
  },
  // {
  //   path: "/american-football/contest/matches",
  //   component: lazy(() =>
  //     import("./views/panels/americanFootball/contest/ContestMatches")
  //   ),
  //   layout: 1,
  // },
  {
    path: "/american-football/contest/add-competitor",
    component: lazy(() =>
      import("./views/panels/americanFootball/contest/AddParticipant")
    ),
    layout: 1,
  },
  {
    path: "/american-football/contest/add-rounds",
    component: lazy(() => import("./features/contest/AddRounds")),
    layout: 1,
  },
  {
    path: "/american-football/contest/matches/scrapper",
    component: lazy(() =>
      import("./features/matchesscrapper/MatchesScrapperPage")
    ),
    layout: 1,
  },

  //#endregion contest end

  //#region match
  {
    path: "/american-football/match",
    component: lazy(() =>
      import("./views/panels/americanFootball/match/Matches")
    ),
    layout: 1,
  },
  {
    path: "/american-football/match/add",
    component: lazy(() =>
      import("./views/panels/americanFootball/match/MatchForm")
    ),
    layout: 1,
  },
  {
    path: "/american-football/match/update",
    component: lazy(() =>
      import("./views/panels/americanFootball/match/MatchForm")
    ),
    layout: 1,
  },
  {
    path: "/american-football/match/scores",
    component: lazy(() =>
      import("./views/panels/americanFootball/match/scores/MatchScores")
    ),
    layout: 1,
  },
  {
    path: "/american-football/match/stats",
    component: lazy(() =>
      import("./views/panels/americanFootball/match/MatchStats")
    ),
    layout: 1,
  },
  //#endregion match

  //#region venue
  {
    path: "/american-football/venue",
    component: lazy(() =>
      import("./views/panels/americanFootball/venue/Venue")
    ),
    layout: 1,
  },
  {
    path: "/american-football/venue/detail",
    component: lazy(() =>
      import("./views/panels/americanFootball/venue/VenueDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/american-football/venue/add",
    component: lazy(() =>
      import("./views/panels/americanFootball/venue/VenueForm")
    ),
    layout: 1,
  },
  {
    path: "/american-football/venue/update",
    component: lazy(() =>
      import("./views/panels/americanFootball/venue/VenueForm")
    ),
    layout: 1,
  },
  //#endregion venue

  //#endregion american-football

  // #startregion Floorball
  {
    path: "/floorball/tournament",
    component: lazy(() =>
      import("./views/panels/floorball/tournament/Tournament")
    ),
    layout: 1,
  },
  {
    path: "/floorball/tournament/detail",
    component: lazy(() =>
      import("./views/panels/floorball/tournament/TournamentDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/floorball/tournament/add",
    component: lazy(() =>
      import("./views/panels/floorball/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/floorball/tournament/update",
    component: lazy(() =>
      import("./views/panels/floorball/tournament/TournamentForm")
    ),
    layout: 1,
  },
  // #endregion Tournament
  //#region contest
  {
    path: "/floorball/contest",
    component: lazy(() => import("./views/panels/floorball/contest/Contest")),
    layout: 1,
  },
  {
    path: "/floorball/contest/detail",
    component: lazy(() =>
      import("./views/panels/floorball/contest/ContestDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/floorball/contest/add",
    component: lazy(() =>
      import("./views/panels/floorball/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/floorball/contest/update",
    component: lazy(() =>
      import("./views/panels/floorball/contest/ContestForm")
    ),
    layout: 1,
  },
  {
    path: "/floorball/contest/add-rounds",
    component: lazy(() => import("./features/contest/AddRounds")),
    layout: 1,
  },
  {
    path: "/floorball/contest/add-competitor",
    component: lazy(() =>
      import("./views/panels/floorball/contest/AddParticipant")
    ),
    layout: 1,
  },
  {
    path: "/floorball/competitor/add-competitor-to-contest",
    component: lazy(() =>
      import("./views/panels/floorball/competitor/AddCompToContestForm")
    ),
    layout: 1,
  },
  {
    path: "/floorball/contest-matches",
    component: lazy(() =>
      import("./views/panels/floorball/contest/ContestMatches")
    ),
    layout: 1,
  },
  //#endregion contest end
  //#region competitor
  {
    path: "/floorball/competitor",
    component: lazy(() =>
      import("./views/panels/floorball/competitor/Competitor")
    ),
    layout: 1,
  },
  {
    path: "/floorball/competitor/detail",
    component: lazy(() =>
      import("./views/panels/floorball/competitor/CompetitorDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/floorball/competitor/update",
    component: lazy(() =>
      import("./views/panels/floorball/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/floorball/competitor/add",
    component: lazy(() =>
      import("./views/panels/floorball/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/floorball/competitor/add-competitor-to-contest",
    component: lazy(() =>
      import("./views/panels/floorball/competitor/AddCompToContestForm")
    ),
    layout: 1,
  },
  //#region Matches

  // {
  //   path: "/floorball/match",
  //   component: lazy(() => import("./views/panels/floorball/match/Matches")),
  //   layout: 1,
  // },
  {
    path: "/floorball/match",
    component: lazy(() =>
      import("./views/panels/floorball/match/FloorballMatches")
    ),
    layout: 1,
  },
  {
    path: "/floorball/match/add",
    component: lazy(() => import("./views/panels/floorball/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/floorball/match/update",
    component: lazy(() => import("./views/panels/floorball/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/floorball/match/scores",
    component: lazy(() =>
      import("./views/panels/floorball/match/scores/MatchScores")
    ),
    layout: 1,
  },
  //#region venue
  {
    path: "/floorball/venue",
    component: lazy(() => import("./views/panels/floorball/venue/Venue")),
    layout: 1,
  },
  {
    path: "/floorball/venue/detail",
    component: lazy(() =>
      import("./views/panels/floorball/venue/VenueDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/floorball/venue/add",
    component: lazy(() => import("./views/panels/floorball/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/floorball/venue/update",
    component: lazy(() => import("./views/panels/floorball/venue/VenueForm")),
    layout: 1,
  },
  //#endregion venue

  // Section Rugby
  {
    path: "/rugby/tournament",
    component: lazy(() => import("./views/panels/rugby/tournament/Tournament")),
    layout: 1,
  },
  {
    path: "/rugby/tournament/detail",
    component: lazy(() =>
      import("./views/panels/rugby/tournament/TournamentDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/rugby/tournament/add",
    component: lazy(() =>
      import("./views/panels/rugby/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/rugby/tournament/update",
    component: lazy(() =>
      import("./views/panels/rugby/tournament/TournamentForm")
    ),
    layout: 1,
  },

  {
    path: "/rugby/competitor",
    component: lazy(() => import("./views/panels/rugby/competitor/Competitor")),
    layout: 1,
  },
  {
    path: "/rugby/competitor/add",
    component: lazy(() =>
      import("./views/panels/rugby/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/rugby/competitor/update",
    component: lazy(() =>
      import("./views/panels/rugby/competitor/CompetitorForm")
    ),
    layout: 1,
  },
  {
    path: "/rugby/competitor/add-competitor-to-contest",
    component: lazy(() =>
      import("./views/panels/rugby/competitor/AddCompToContestForm")
    ),
    layout: 1,
  },
  {
    path: "/rugby/competitor/add-coach",
    component: lazy(() =>
      import("./views/panels/rugby/competitor/AssignCoachToCompForm")
    ),
    layout: 1,
  },
  {
    path: "/rugby/competitor/detail",
    component: lazy(() =>
      import("./views/panels/rugby/competitor/CompetitorDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/rugby/competitor/add-squad",
    component: lazy(() =>
      import("./views/panels/rugby/competitor/AddCompetitorSquad")
    ),
    layout: 1,
  },
  {
    path: "/rugby/venue",
    component: lazy(() => import("./views/panels/rugby/venue/Venue")),
    layout: 1,
  },
  {
    path: "/rugby/venue/detail",
    component: lazy(() =>
      import("./views/panels/rugby/venue/VenueDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/rugby/venue/add",
    component: lazy(() => import("./views/panels/rugby/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/rugby/venue/update",
    component: lazy(() => import("./views/panels/rugby/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/rugby/coach",
    component: lazy(() => import("./views/panels/rugby/coach/RugbyCoach")),
    layout: 1,
  },
  {
    path: "/rugby/coach/detail",
    component: lazy(() =>
      import("./views/panels/rugby/coach/CoachDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/rugby/coach/add",
    component: lazy(() => import("./views/panels/rugby/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/rugby/coach/update",
    component: lazy(() => import("./views/panels/rugby/coach/CoachForm")),
    layout: 1,
  },
  {
    path: "/rugby/coach/add-role",
    component: lazy(() => import("./views/panels/rugby/coach/CoachRoleForm")),
    layout: 1,
  },
  {
    path: "/rugby/official",
    component: lazy(() => import("./views/panels/rugby/official/Official")),
    layout: 1,
  },
  {
    path: "/rugby/official/detail",
    component: lazy(() =>
      import("./views/panels/rugby/official/OfficialDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/rugby/official/add",
    component: lazy(() => import("./views/panels/rugby/official/OfficialForm")),
    layout: 1,
  },
  {
    path: "/rugby/official/update",
    component: lazy(() => import("./views/panels/rugby/official/OfficialForm")),
    layout: 1,
  },
  {
    path: "/rugby/player",
    component: lazy(() => import("./views/panels/rugby/player/Player")),
    layout: 1,
  },
  {
    path: "/rugby/player/detail",
    component: lazy(() =>
      import("./views/panels/rugby/player/PlayerDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/rugby/player/update",
    component: lazy(() => import("./views/panels/rugby/player/PlayerForm")),
    layout: 1,
  },
  {
    path: "/rugby/player/add",
    component: lazy(() => import("./views/panels/rugby/player/PlayerForm")),
    layout: 1,
  },
  {
    path: "/rugby/player/transfer",
    component: lazy(() =>
      import("./views/panels/rugby/player/PlayerTransferForm")
    ),
    layout: 1,
  },
  {
    path: "/rugby/player/squad",
    component: lazy(() =>
      import("./views/panels/rugby/player/AddPlayerToComp")
    ),
    layout: 1,
  },
  {
    path: "/rugby/player/statistics",
    component: lazy(() =>
      import("./views/panels/rugby/player/playerStatisticsForm")
    ),
    layout: 1,
  },
  {
    path: "/rugby/contest",
    component: lazy(() => import("./views/panels/rugby/contest/Contest")),
    layout: 1,
  },
  {
    path: "/rugby/contest/detail",
    component: lazy(() =>
      import("./views/panels/rugby/contest/ContestDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/rugby/contest/add",
    component: lazy(() => import("./views/panels/rugby/contest/ContestForm")),
    layout: 1,
  },
  {
    path: "/rugby/contest/update",
    component: lazy(() => import("./views/panels/rugby/contest/ContestForm")),
    layout: 1,
  },
  {
    path: "/rugby/contest/add-competitor",
    component: lazy(() =>
      import("./views/panels/rugby/contest/AddParticipant")
    ),
    layout: 1,
  },
  {
    path: "/rugby/contest/add-rounds",
    component: lazy(() => import("./views/panels/rugby/contest/AddRounds")),
    layout: 1,
  },
  // {
  //   path: "/rugby/contest/matches",
  //   component: lazy(() =>
  //     import("./views/panels/rugby/contest/ContestMatches")
  //   ),
  //   layout: 1,
  // },

  //#region Golf Tournament Routes
  {
    path: "/golf/tournament",
    component: lazy(() => import("./views/panels/golf/tournament/Tournament")),
    layout: 1,
  },
  {
    path: "/golf/tournament/detail",
    component: lazy(() =>
      import("./views/panels/golf/tournament/TournamentDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/golf/tournament/add",
    component: lazy(() =>
      import("./views/panels/golf/tournament/TournamentForm")
    ),
    layout: 1,
  },
  {
    path: "/golf/tournament/update",
    component: lazy(() =>
      import("./views/panels/golf/tournament/TournamentForm")
    ),
    layout: 1,
  },

  //#endregion

  //#region Golf venue
  {
    path: "/golf/venue",
    component: lazy(() => import("./views/panels/golf/venue/Venue")),
    layout: 1,
  },
  {
    path: "/golf/venue/detail",
    component: lazy(() => import("./views/panels/golf/venue/VenueDetailModal")),
    layout: 1,
  },
  {
    path: "/golf/venue/add",
    component: lazy(() => import("./views/panels/golf/venue/VenueForm")),
    layout: 1,
  },
  {
    path: "/golf/venue/update",
    component: lazy(() => import("./views/panels/golf/venue/VenueForm")),
    layout: 1,
  },
  //#endregion venue

  //#region Golf Players Routes
  {
    path: "/golf/player",
    component: lazy(() => import("./views/panels/golf/player/Player")),
    layout: 1,
  },
  {
    path: "/golf/player/detail",
    component: lazy(() =>
      import("./views/panels/golf/player/PlayerDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/golf/player/add",
    component: lazy(() => import("./views/panels/golf/player/PlayerForm")),
    layout: 1,
  },
  {
    path: "/golf/player/update",
    component: lazy(() => import("./views/panels/golf/player/PlayerForm")),
    layout: 1,
  },

  //#endregion

  //#region Golf Player Group Routes
  {
    path: "/golf/player-group",
    component: lazy(() =>
      import("./views/panels/golf/playergroup/PlayerGroup")
    ),
    layout: 1,
  },
  {
    path: "/golf/player-group/detail",
    component: lazy(() =>
      import("./views/panels/golf/playergroup/PlayerGroupDetailModal")
    ),
    layout: 1,
  },
  {
    path: "/golf/player-group/add",
    component: lazy(() =>
      import("./views/panels/golf/playergroup/PlayerGroupForm")
    ),
    layout: 1,
  },
  {
    path: "/golf/player-group/update",
    component: lazy(() =>
      import("./views/panels/golf/playergroup/PlayerGroupForm")
    ),
    layout: 1,
  },

  //#endregion

  //# region golf matches

  {
    path: "/golf/match",
    component: lazy(() => import("./views/panels/golf/match/GolfMatches")),
    layout: 1,
  },
  {
    path: "/golf/match/add",
    component: lazy(() => import("./views/panels/golf/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/golf/match/update",
    component: lazy(() => import("./views/panels/golf/match/MatchForm")),
    layout: 1,
  },
  {
    path: "/golf/match/scores",
    component: lazy(() => import("./views/panels/golf/match/MatchScores")),
    layout: 1,
  },
  //#endregion
];

export default dashboardRoutes;
